@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    h1, h2, h3, h4, h5 {
        @apply font-bold;
    }
    h1 {
        @apply text-3xl;
    }
    h2 {
        @apply text-2xl;
    }
    h3 {
        @apply text-xl;
    }
    h4 {
        @apply text-lg;
    }
    h5 {
        @apply text-sm;
    }

    a{
        @apply text-merah;
    }

    ol{
        list-style: decimal;
        padding-left: 16px;
    }

    .btn-merah {
        @apply flex;
        @apply justify-center; 
        @apply w-full;
        @apply py-3;
        @apply mt-6;
        @apply mr-3;
        @apply font-medium;
        @apply leading-snug;
        @apply text-white;
        @apply text-base;
        @apply transition;
        @apply duration-150;
        @apply ease-in-out;
        @apply rounded;
        @apply shadow-md;
        @apply bg-merah;
        @apply px-7;
    }
    .btn-merah:hover{
        @apply bg-gelap;
    }

    .pmb-belum-mulai{
        @apply bg-gray-100;
        @apply text-gray-700;
    }
    /* .pmb-belum-mulai:hover{
        @apply bg-gray-200;
    } */
    .pmb-belum-mulai .step{
        @apply bg-gray-700;
    }
    .pmb-dalam-proses{
        @apply bg-yellow-100;
        @apply text-yellow-700;
    }
    /* .pmb-dalam-proses:hover{
        @apply bg-yellow-200;
    } */
    .pmb-dalam-proses .step{
        @apply bg-yellow-700;
    }
    .pmb-berhasil{
        @apply bg-green-100;
        @apply text-green-700;
    }
    /* .pmb-berhasil:hover{
        @apply bg-green-200;
    } */
    .pmb-berhasil .step{
        @apply bg-green-700;
    }
    .pmb-gagal{
        @apply bg-red-100;
        @apply text-red-700;
    }
    /* .pmb-gagal:hover{
        @apply bg-red-200;
    } */
    .pmb-proses .step{
        @apply bg-red-700;
    }
    
    /* focus:bg-gelap focus:shadow-lg focus:outline-none focus:ring-0 active:bg-merah active:shadow-lg */
}