/* @import "../node_modules/@syncfusion/ej2-base/styles/tailwind.css";
@import '../node_modules/@syncfusion/ej2-react-buttons/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-react-popups/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-react-notifications/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-react-inputs/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/tailwind.css';   */
@import '../node_modules/@syncfusion/ej2-base/styles/tailwind-nusa.css';  

/* @tailwind base; */
.ReactModal__Overlay.ReactModal__Overlay--after-open{
  z-index: 7;
}
.sidebar {
  z-index: 3;
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  /* border: none; */
}


.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input {
  height: 36px;
}
.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]), .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]){
  border-color: rgb(143 13 30);
}

.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: rgb(143 13 30);
  border-radius: 4px;
  box-shadow: none;
}

/* DIALOG */
.e-dialog{
  padding-bottom: 8px;
}
.e-popup.e-popup-open.e-dialog{
}
.e-dialog .e-dlg-header-content{
  background-color: rgb(143 13 30);
  border-radius: 6px 6px 0px 0px;
}
.e-dialog .e-dlg-header {
  color: #ffffff;
}
.e-dialog .e-dlg-content {
}
.e-dlg-overlay {
}
.e-icon-dlg-close{
  color: #ffffff;
}
.e-dialog .e-footer-content{
  margin-top: 12px;
}

/* SORTING */
.e-grid .e-icon-ascending::before, 
.e-grid-menu .e-icon-ascending::before,
.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(.e-icon-reorderdownarrow),
{
  @apply text-merah;
}

/* GRID */
.e-grid, 
.e-grid .e-rowcell {
  font-size: 12px !important;
}
.e-grid .e-rowcell{
}
.e-grid .e-row:hover .e-rowcell {
  background-color: rgb(204 229 255 /0.5) !important;
}
.e-grid .e-rowcell.e-selectionbackground {
  background-color: rgb(143 13 30 /0.1);
}
.e-grid .e-row.e-altrow {
  background-color: #fdfdfd;
}
.e-grid .e-row {
  background-color: #ffffff;
}
.e-grid .e-res-toolbar .e-toolbar-items .e-responsive-right{
  width: auto;
}
.e-grid .e-res-toolbar .e-toolbar-items .e-toolbar-right .e-search-wrapper .e-input-group .e-input{
  height: 28px;;
}
/* .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input{
  height: 28px;;
} */
.e-toolbar .e-toolbar-items:first-child > .e-toolbar-item:last-child, .e-toolbar .e-toolbar-items:first-child > .e-toolbar-right .e-toolbar-item:last-child{
  margin-right: 0;
}
.e-grid .e-res-toolbar .e-toolbar-items .e-toolbar-right .e-search-wrapper{
  padding-right: 3px;
}
.e-grid .e-res-toolbar .e-toolbar-items .e-toolbar-right .e-search-wrapper .e-input-group{
  padding-right: 0 !important;
}
.e-grid .e-toolbar-items input.e-input{
  height: 28px;
}
.e-grid .e-res-toolbar .e-toolbar-items .e-toolbar-right .e-search-wrapper .e-search-icon{
  margin-top: 1px;
  padding-right: 9px;
}

.e-grid .e-rowcell.customcss{
  /* background-color: #ecedee; */
  /* font-family: 'Bell MT'; */
  /* color: 'red'; */
  /* font-size: '20px'; */
  }
  
  .e-grid .e-headercell.customcss{
  /* background-color: #2382c3;
  color: white; */
  /* font-family: 'Bell MT'; */
  /* font-size: '20px'; */
  }
  .e-grid .e-gridheader .e-headercell.customcss .e-headercelldiv{
    padding-right: 5px;
  }
  .e-grid .e-headercell.customcss .e-sortfilterdiv{
    display: none;
  }
  .e-grid .e-rowcell.customcss .e-checkbox-wrapper .e-frame + .e-label, .e-css.e-checkbox-wrapper .e-frame + .e-label{
    display: none;
  }
  .e-grid .e-rowcell .e-btn.e-outline{
    background-color: rgb(247 247 247);
    color: rgb(143 13 30);
    border-color: rgb(247 247 247);
  }
  .e-grid .e-rowcell .e-btn.e-outline:hover{
    background-color: rgb(143 13 30);
    color: #ffffff;
    border-color: rgb(143 13 30);
  }
  .e-grid .sort-icon .e-sortfilterdiv:not(.e-icon-ascending):not(.e-icon-descending)::before {
    content: "\e824";
    font-size: 10px;
  }
  .e-grid .e-gridheader thead .e-icons:not(.e-check):not(.e-stop) {
    font-size: 12px;
  }
  .e-grid .e-columnheader.e-wrap .e-sortfilterdiv{
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -11px;
  }
  .e-rowcell.lulus{
    color: rgb(22 163 74) !important;
    font-weight: 600;
  }
  .e-rowcell.tidak-lulus{
    color: rgb(143 13 30) !important;
    font-weight: 600;
  }
  .e-rowcell.published{
    color: rgb(22 163 74) !important;
    font-weight: 600;
    font-family: "e-icons";
    font-size: 0px !important;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 0;
    text-transform: none;
  }
  .e-rowcell.published:before {
    content: "\e774";
    font-size: 24px !important;
  }
  .e-rowcell.unpublish{
    font-size: 0px !important;
  }
  .e-rowcell.unpublish:before {
    font-size: 0px !important;
  }


/* UPLOADER */
.e-upload-actions,
.e-icons.e-file-delete-btn,
.e-upload .e-upload-files .e-file-remove-btn.e-icons{
  /* display: none !important; */
}